export const getAllEvents = async (app) => {
  try {
    const mongo = app.currentUser.mongoClient("mongodb-atlas");
    const eventsCollection = mongo.db(process.env.REACT_APP_DB_NAME).collection("events");
    let d = new Date();
    const events = await eventsCollection.aggregate([
      {
        $lookup: {
          from: "entries",
          localField: "_id",
          foreignField: "event",
          as: "entries",
        },
      },
      {
        $match:
        /**
         * query: The query in MQL.
         */
          {
            date: {
              $gte: new Date(d.setDate(d.getDate() - 2)),
            },
          },
      },
      {
        $project: {
          length: {
            $size: "$entries",
          },
          _id: 1,
          name: 1,
          date: 1,
          city: 1,
          state: 1,
          grounds: 1,
          purse: 1,
          round: 1,
          outside_entries: 1,
          type: 1,
        },
      },
      {
        $sort: {
          date: 1,
        },
      },
      {
        $limit: 10,
      },
    ]);
    return {events};
  } catch (error) {
    console.error("Error fetching events:", error);
  }
};

export const getEvents = async (app, eventName, year) => {
  try {
    const mongo = app.currentUser.mongoClient("mongodb-atlas");
    const eventsCollection = mongo.db(process.env.REACT_APP_DB_NAME).collection("events");
    let lookups = [
      {
        '$lookup': {
          'from': 'sidepots',
          'localField': '_id',
          'foreignField': 'eventId',
          'as': 'sidepot'
        }
      },
      {
        '$unwind': {
          'path': '$sidepot',
          'preserveNullAndEmptyArrays': true
        }
      },
      {
        '$match': {
          'sidepot.finalized': {'$ne': null},
          'sidepot.class': {'$eq': "ALL"} // Show ALL sidepots only
        }
      },
      {
        '$lookup': {
          'from': 'sidePotEntryJoins',
          'localField': 'sidepot._id',
          'foreignField': 'sidePotId',
          'as': 'entry'
        }
      },
      {
        '$unwind': {
          'path': '$entry',
          'preserveNullAndEmptyArrays': true
        }
      },
      {
        '$lookup': {
          'from': 'entries',
          'localField': 'entry.entryId',
          'foreignField': '_id',
          'as': 'entry'
        }
      },
      {
        '$unwind': {
          'path': '$entry',
          'preserveNullAndEmptyArrays': true
        }
      },
      {
        '$lookup': {
          'from': 'placements',
          'let': {'sidePotId': '$sidepot._id', 'entryId': '$entry._id'},
          'pipeline': [
            {
              '$match': {
                '$expr': {
                  '$and': [
                    {'$eq': ['$sidePot', '$$sidePotId']},
                    {'$eq': ['$entryId', '$$entryId']}
                  ]
                }
              }
            }
          ],
          'as': 'sidepot.placement'
        }
      },
      {
        '$unwind': {
          'path': '$sidepot.placement',
          'preserveNullAndEmptyArrays': true
        }
      },
      {
        '$lookup': {
          'from': 'foals',
          'localField': 'entry.foal',
          'foreignField': '_id',
          'as': 'foal'
        }
      },
      {
        '$unwind': {
          'path': '$foal',
          'preserveNullAndEmptyArrays': true
        }
      },
      {
        $group: {
          _id: {
            foal: '$entry.foal',
            event: '$_id'
          },
          sidepot: {$addToSet: '$sidepot'},
          entry: {$addToSet: '$entry'},
          foal: {$addToSet: '$foal'},
          date: {$first: '$date'},
          name: {$first: '$name'},
        }
      },
      {
        '$unwind': {
          'path': '$sidepot',
          'preserveNullAndEmptyArrays': true
        }
      },
      {
        '$unwind': {
          'path': '$entry',
          'preserveNullAndEmptyArrays': true
        }
      },
      {
        '$unwind': {
          'path': '$foal',
          'preserveNullAndEmptyArrays': true
        }
      },
      {
        '$lookup': {
          'from': 'riders',
          'localField': 'entry.rider',
          'foreignField': '_id',
          'as': 'rider'
        }
      },
      {
        '$unwind': {
          'path': '$rider',
          'preserveNullAndEmptyArrays': true
        }
      },
      {
        '$lookup': {
          'from': 'nominators',
          'localField': 'foal.nominator',
          'foreignField': '_id',
          'as': 'nominator'
        }
      },
      {
        '$unwind': {
          'path': '$nominator',
          'preserveNullAndEmptyArrays': true
        }
      },
      {
        '$lookup': {
          'from': 'owners',
          'localField': 'entry.owner',
          'foreignField': '_id',
          'as': 'owner'
        }
      },
      {
        '$unwind': {
          'path': '$owner',
          'preserveNullAndEmptyArrays': true
        }
      },
      {
        '$lookup': {
          'from': 'sires',
          'localField': 'foal.sire',
          'foreignField': '_id',
          'as': 'sire'
        }
      },
      {
        '$unwind': {
          'path': '$sire',
          'preserveNullAndEmptyArrays': true
        }
      },
    ];

    const yearAdd = [
      {
        '$addFields': {
          'year': {
            '$cond': {
              'if': {
                '$or': [
                  // after November 15
                  {
                    '$and': [
                      { '$eq': [{ '$month': '$date' }, 11] },
                      { '$gt': [{ '$dayOfMonth': '$date' }, 15] }
                    ]
                  },
                  // December
                  {
                    '$eq': [{ '$month': '$date' }, 12]
                  }
                ]
              },
              'then': {
                '$add': [
                  {
                    '$year': '$date'
                  }, 1
                ]
              },
              'else': {
                '$year': '$date'
              }
            }
          }
        }
      }
    ];
    const project = [
      {
        '$project': {
          '_id': 1,
          'entryId': '$entry._id',
          'place': '$sidepot.placement.place',
          'time': '$entry.time',
          'class': '$sidepot.class',
          'riderName': '$rider.name',
          'foalName': '$foal.name',
          'nominatorName': '$nominator.name',
          'ownerName': '$owner.name',
          'sireName': '$sire.name',
          'eventName': '$name',
          'eventDate': '$date',
          'year': 1
        }
      },
    ]
    const matchFilters = [];

    if (eventName) {
      matchFilters.push({
        '$match': {
          'name': eventName
        }
      });
    }

    if (year) {
      matchFilters.push({
        '$match': {
          'year': Number(year)
        }
      });
    }
    const sort = [
      {
        '$sort': {
          'time': 1
        }
      }
    ];

    const events = await eventsCollection.aggregate([
      ...lookups,
      ...yearAdd,
      ...matchFilters,
      ...project,
      ...sort
    ]);

    return {events};
  } catch (e) {
    throw new Error()
  }
}