// Imports
import axios from "axios";
import {createRealmApp} from './RealmApp'

require("./interceptor");
const dataSource = process.env.REACT_APP_MONGO_DATASOURCE;
const database = process.env.REACT_APP_MONGO_DATABASE;

export function pricingRulesEngine(foalBirthYear) {
  let price = 0;
  let today = new Date();

  //uncomment for testing only
  //let today = new Date("January 1, 2025")
  //let today = new Date("January 1, 2026")

  let year = today.getFullYear();
  let foalAge = year - Number(foalBirthYear);

  const earlyRegistrationEnd = new Date("December 31, 2024");
  const afterDueDate = new Date("December 1, 2025");

  // before or on December 31st, 2024
  if (today <= earlyRegistrationEnd) {
    price = 500;
  } else if (today > afterDueDate) {
    // past due date December 1st, 2025, add 1 year to foal's age
    foalAge += 1;
  }

  // TLM rules
  if (price === 0) {
    if (foalAge >= 4) {
      price = 2000;
    } else if (foalAge === 3) {
      price = 1000;
    } else if (foalAge === 2) {
      price = 750;
    } else {
      price = 500; // yearling
    }
  }

  return price;
}

const insert = (collection, data) => {
  return axios
  .post(`/action/insertOne`, {
    dataSource,
    database,
    collection: collection,
    document: data,
  })
  .then((response) => {
    return response.data.documents;
  })
  .catch((error) => {
    throw error;
  });
};

const get = (collection, query) => {
  return axios
  .post(`/action/find`, {
    dataSource,
    database,
    collection: collection,
    filter: query,
  })
  .then((response) => {
    return response.data.documents;
  })
  .catch((error) => {
    throw error;
  });
};


export async function generateId() {
  const app = createRealmApp();
  const mongo = app.currentUser.mongoClient('mongodb-atlas');
  const db = mongo.db(process.env.REACT_APP_DB_NAME);
  const last = await db
  .collection('nominators')
  .find({}, {limit: 1, sort: {membershipId: -1}});
  let num;
  if (last.length > 0) {
    num = Number(last[0].membershipId.split('LM-')[1]);
  } else {
    num = 0;
    console.log('No existing membership ids.');
  }

  const newId = `LM-${(num + 1).toString().padStart(6, '0')}`;
  return newId;
}


export const formatMoney = (amount) => {
  if (!amount) return "";
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }).format(amount);
}

export const formatDate = (dateString) => {
  let date = dateString
  if (!dateString) return "";
  if (typeof date == 'string') date = new Date(dateString);

  return new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    timeZone: 'UTC'
  }).format(date);
};